<template>
	<q-card v-if="current_incident" :class="$q.screen.gt.xs ? 'q-ma-xl' : 'q-ma-sm'">
		<Incident 
			:incident=current_incident
		/>
	</q-card>
</template>

<script>
import Incident from "@/components/incidents/incidents/Incident.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "IncidentEdit",
	components: { Incident },
	methods: mapActions(['getIncident']),
	computed: mapGetters(['current_incident']),
	async created() {
		let incident_id = parseInt(this.$route.params.id)
		this.$q.loading.show({
			delay: 400,
		})
		await this.getIncident(incident_id)
		this.$q.loading.hide()
	},
};
</script>
